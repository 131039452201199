import React, { useState } from "react";
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Checkbox,
} from "react-bootstrap/lib";

import { translateWithGlobals } from "../../utils/Translations";
import { generateLoginToken } from "../../utils/LoginToken";
import { PasswordToken, SendSms } from "../";
import { manageWorklogs, createProjects } from "../../utils/Constants";
import { samePermission } from "../../utils/HelperFunctions";
import { isValidPhoneNumberForm } from "../SendSms";

// prettier-ignore
const translations = {
  "Password": { is: "Lykilorð" },
  "Permissions": { is: "Réttindi" },

  "Employee can manually add and edit time entries in the app": {
    is: "Starfsmaður má handskrá og breyta vinnufærslum í appinu",
  },
  "Employee can manually add projects in the app": { is: "Starfsmaður má búa til verkefni í appinu" },

  // This odd translation entry is so the Icelandic translation would read
  // right. "Breyta starfsmanna nafni" instead of "Breyta starfsmanni nafni".
  "editEmployee": {
    en: "Edit employee",
    is: "Breyta starfsmanna"
  },
  "Edit employee": { is: "Breyta starfsmanni" },
  "name": { is: "nafni"},
  "password": { is: "lykilorði" },
  "permission": { is: "réttindum" },
  "send SMS": { is: "senda SMS" },
  "Send login SMS": { is: "Senda innskráningar SMS" },

  "Activate employee": { is: "Virkja starfsmann" },
  "Deactivate employee": { is: "Óvirkja starfsmann" },

  "Confirmation": { is: "Staðfesting" },
  "Yes I want to deactivate the employee": {
    is: "Já ég vil gera starfsmann óvirkann"
  },

  "Deactivate_employee_explanation": {
    en: "The employee will not be able to log into the app nor create time " +
      "entries. The employee can be activated again later.",
    is: "Starfsmaðurinn mun ekki geta skráð sig inn í appið né búið til " +
      "færslur. Hægt er að virkja starfsmanninn aftur seinna.",
  },
  "Cancel": { "is": "Hætta við" },
};

const t = key => translateWithGlobals(key, translations);

/**
 * A green button for confirming an update on employee details. If details have
 * not been changed the button is disabled. Otherwise, the button is enabled
 * and spells out what is about to change.
 */
function EditEmployeeButton(props) {
  const { username, token, needs, employee, phoneNumber, submit } = props;

  const usernameIsNotEmpty = username.length !== 0;
  const usernameChanged = username !== employee?.name;
  const tokenChanged = token !== employee?.login_token;
  const needsChanged = !samePermission(needs, employee?.needs);
  const phoneEmpty = phoneNumber === "";
  const phoneValid = isValidPhoneNumberForm(phoneNumber);
  const somethingChanged = usernameChanged || tokenChanged || needsChanged;
  const nothingChanged = !somethingChanged;
  const enableSubmit =
    usernameIsNotEmpty &&
    (phoneEmpty || phoneValid) &&
    (somethingChanged || phoneValid);

  // Make submit button label read exactly what will happen if pressed
  let actions = [];
  let label = t("Edit employee");
  if (usernameChanged) actions.push(t("name"));
  if (tokenChanged) actions.push(t("password"));
  if (needsChanged) actions.push(t("permission"));
  if (phoneNumber) actions.push(t("send SMS"));
  if (somethingChanged) label = t("editEmployee") + " " + actions.join(" & ");
  if (phoneNumber && nothingChanged) label = t("Send login SMS");

  return (
    <Button
      disabled={!enableSubmit}
      type="submit"
      bsStyle="success"
      block={true}
      onClick={submit}
    >
      {label}
    </Button>
  );
}

/** If the employee is active display a Deactivate button and vice versa. */
function ActivateDeactivateButton(props) {
  const { employee, onDeactivate, onActivate } = props;

  const disableButton = (
    <Button bsStyle="danger" block={true} onClick={onDeactivate}>
      {t("Deactivate employee")}
    </Button>
  );

  const enableButton = (
    <Button bsStyle="success" block={true} onClick={onActivate}>
      {t("Activate employee")}
    </Button>
  );

  return employee.disabled ? enableButton : disableButton;
}

function EditEmployee(props) {
  const { show, employee, onSubmit, onCloseModal, onChangeEmployeeState } =
    props;

  const [username, setUsername] = useState(employee?.name ?? "");
  const [token, setToken] = useState(employee?.login_token ?? "");
  const [needs, setNeeds] = useState(employee?.needs ?? []);
  const [phoneNumber, setPhoneNumber] = useState(employee?.phone_number ?? "");
  const [showDisableEmployeeWarning, setShowDisableEmployeeWarning] =
    useState(false);

  const hasManageWorklogPermission = needs.includes(manageWorklogs);
  const needsWithoutManageWrklg = needs.filter(need => need !== manageWorklogs);

  const hasCreateProjectsPermission = needs.includes(createProjects);
  const needsWithoutCreateProjects = needs.filter(
    need => need !== createProjects
  );

  const handleNameChange = e => setUsername(e.target.value);
  const refreshToken = () => setToken(generateLoginToken());
  const toggleManageWorklogPermission = () => {
    if (needs.includes(manageWorklogs)) {
      setNeeds(needsWithoutManageWrklg);
    } else {
      setNeeds([...needs, manageWorklogs]);
    }
  };
  const toggleCreateProjectsPermission = () => {
    if (needs.includes(createProjects)) {
      setNeeds(needsWithoutCreateProjects);
    } else {
      setNeeds([...needs, createProjects]);
    }
  };

  const submit = e => {
    e.preventDefault();
    onSubmit({
      id: employee.id,
      new_name: username,
      new_token: token,
      needs: needs,
      phone_number: phoneNumber,
    });
    onCloseModal();
  };

  /** Activate or de-activate an employee */
  const changeEmployeeState = employee => {
    onChangeEmployeeState(employee);
    onCloseModal();
  };

  if (!employee) return <div />;

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Edit employee")}</Modal.Title>
      </Modal.Header>
      {showDisableEmployeeWarning ? (
        <div>
          <Alert bsStyle="warning">
            <h4>{t("Confirmation")}</h4>
            <p>{t("Deactivate_employee_explanation")}</p>
            <p />
          </Alert>
          <Modal.Footer>
            <Button
              bsStyle="danger"
              onClick={() => changeEmployeeState(employee)}
            >
              {t("Yes I want to deactivate the employee")}
            </Button>
            <Button onClick={onCloseModal}>{t("Cancel")}</Button>
          </Modal.Footer>
        </div>
      ) : (
        <Form onSubmit={submit}>
          <Modal.Body>
            <FormGroup controlId="formEmployeeNameControl">
              <ControlLabel>{t("Name")}</ControlLabel>
              <FormControl
                type="text"
                onChange={handleNameChange}
                value={username}
              />
            </FormGroup>
            <FormGroup controlId="formEmployeeTokenControl">
              <ControlLabel>{t("Password")}</ControlLabel>
              <PasswordToken token={token} onRefreshToken={refreshToken} />
            </FormGroup>
            <FormGroup controlId="formEmployeePermissionControl">
              <ControlLabel>{t("Permissions")}</ControlLabel>
              <Checkbox
                checked={hasManageWorklogPermission}
                onChange={toggleManageWorklogPermission}
              >
                {t(
                  "Employee can manually add and edit time entries in the app"
                )}
              </Checkbox>
              <Checkbox
                checked={hasCreateProjectsPermission}
                onChange={toggleCreateProjectsPermission}
              >
                {t("Employee can manually add projects in the app")}
              </Checkbox>
            </FormGroup>
            {employee.disabled ? null : (
              <SendSms
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <EditEmployeeButton
              username={username}
              token={token}
              needs={needs}
              phoneNumber={phoneNumber}
              employee={employee}
              submit={submit}
            />

            <ActivateDeactivateButton
              employee={employee}
              onActivate={() => changeEmployeeState(employee)}
              onDeactivate={() => setShowDisableEmployeeWarning(true)}
            />

            <Button block={true} onClick={onCloseModal}>
              {t("Close window")}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
}

export default EditEmployee;
