import sortBy from "../utils/SortBy";

export default function reducer(
  state = {
    data: [],
    sortedBy: {
      // Usually we don't need to know the previousAttribute sorted by
      // As that is part of the previous state when a user changes sorting.
      // But, since we do call `sortBy` automatically upon transaction refresh
      // we have to be able to know the actual previous state in order to maintain
      // the current state.
      prevAttribute: null,
      attribute: "start_time",
      isDesc: true,
    },
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case "DELETE_WORK_LOG":
    case "CHANGE_WORK_LOG":
    case "FETCH_TRANSACTIONS": {
      return { ...state, fetching: true };
    }
    case "FETCH_TRANSACTIONS_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_TRANSACTIONS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
        sortedBy: {
          prevAttribute: null,
          attribute: "start_time",
          isDesc: true,
        },
      };
    }
    case "FETCH_REFRESH_TRANSACTIONS_FULFILLED": {
      if (action.payload && action.payload.length) {
        // Use the same values as before but make sure to flip the desc
        // just in case that the attributes are the same.
        // This is needed to maintain the current state
        const { sorted } = sortBy(
          state.sortedBy.prevAttribute,
          state.sortedBy.attribute,
          !state.sortedBy.isDesc,
          [...action.payload, ...state.data]
        );
        return {
          ...state,
          data: sorted,
        };
      }
      return state;
    }
    case "CHANGE_WORK_LOG_FULFILLED":
    case "ADD_WORK_LOG_COMMENT_FULFILLED": {
      state.data.forEach((item, i) => {
        if (item.id === action.payload.id) state.data[i] = action.payload;
      });
      return { ...state, data: state.data, fetching: false };
    }
    case "DELETE_WORK_LOG_FULFILLED": {
      const newData = state.data.filter(item => item.id !== action.payload.id);
      return { ...state, data: newData, fetching: false };
    }
    case "SORT_TRANSACTION": {
      const attribute = action.payload;
      const prevAttribute = state.sortedBy.attribute;
      const { sorted, isDesc } = sortBy(
        prevAttribute,
        attribute,
        state.sortedBy.isDesc,
        state.data
      );

      return {
        ...state,
        data: sorted,
        sortedBy: {
          prevAttribute: prevAttribute,
          attribute: attribute,
          isDesc: isDesc,
        },
      };
    }
    default: {
      return state;
    }
  }
}
