export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    data: {},
    role: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case "FETCH_AUTHENTICATION_ADMIN":
    case "FETCH_AUTHENTICATION": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        data: {},
        role: null,
        error: null,
      };
    }
    case "FETCH_AUTHENTICATION_ADMIN_REJECTED":
    case "FETCH_AUTHENTICATION_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        data: {},
        role: null,
        error: action.payload,
      };
    }
    case "FETCH_AUTHENTICATION_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
        role: action.payload.role ? action.payload.role : "COMPANY",
        error: null,
      };
    }
    case "FETCH_AUTHENTICATION_ADMIN_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
        role: "ADMIN",
        error: null,
      };
    }
    case "CANCEL_BILLING":
    case "CHANGE_PASSWORD": {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case "CANCEL_BILLING_REJECTED":
    case "CHANGE_PASSWORD_FULFILLED":
    case "CHANGE_PASSWORD_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case "CREATE_PAYDAY_BILLING_FULFILLED": {
      return {
        ...state,
        data: { ...state.data, paying_customer: true },
      };
    }
    case "CANCEL_BILLING_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: { ...state.data, paying_customer: false },
      };
    }
    case "VALIDATE_PADDLE_BILLING_FULFILLED":
    case "UPDATE_LAST_SEEN_NOTIFICATION_FULFILLED": {
      return {
        ...state,
        data: action.payload,
      };
    }
    case "UPDATE_SETTINGS_FULFILLED": {
      return {
        ...state,
        data: { ...state.data, settings: action.payload.settings },
      };
    }
    case "LOG_OUT":
    case "CLEAR_USER_ERROR": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        data: {},
        role: null,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
}
