import React from "react";
import { connect } from "react-redux";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap/lib";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { translate } from "../utils/Translations";

// prettier-ignore
const translations = {
  "Send login SMS (optional)": { is: "Senda innskráningar SMS (valkvætt)" },
  "Enter phone number": { is: "Veljið símanúmer" },
};

const t = key => translate(key, translations);

function SendSms(props) {
  const {
    user,
    phoneNumber,
    setPhoneNumber,
    overrideLabel,
    bsSize = null,
  } = props;
  const label = overrideLabel ? overrideLabel : t("Send login SMS (optional)");

  // Yellow when typing, green on correct format, default style otherwise
  let validationState = "warning";
  if (phoneNumber === "") validationState = null;
  if (isValidPhoneNumberForm(phoneNumber)) validationState = "success";

  return (
    <FormGroup
      controlId="formSMSControl"
      validationState={validationState}
      // If the size is specified, use it, otherwise do not pass down the prop
      bsSize={bsSize}
    >
      <ControlLabel>{label}</ControlLabel>
      <PhoneInput
        defaultCountry={user.data.country}
        placeholder={phoneNumber || " " + t("Enter phone number")}
        value={phoneNumber}
        onChange={setPhoneNumber}
      />
      <FormControl.Feedback />
    </FormGroup>
  );
}

export function isValidPhoneNumberForm(number) {
  // The owner of the library actually recommends not using `isValidPhoneNumber` as:
  //
  // https://www.npmjs.com/package/react-phone-number-input#isvalidphonenumbervalue-string-boolean
  // """
  // I personally don't use isValidPhoneNumber() for phone number validation in my projects.
  // The rationale is that telephone numbering plans can and sometimes do change,
  // meaning that isValidPhoneNumber() function may one day become outdated on a website that isn't actively maintained anymore
  // """
  //
  // We have actually seen this happen on our backend phonenumber library that we use.
  // But I prefer using libraries that we need to remember to keep up to date rather than our own code that is probably more prone to failing
  // and we need to keep up to date as well when phone companies change things up.
  return number && isValidPhoneNumber(number);
}

export default connect(store => {
  return { user: store.user };
})(SendSms);
